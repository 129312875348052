import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { QuoteStepper } from "./ProgressTracker";
import { UserDetailsForm } from "./DetailsForm";
import { EVChargerDetails } from "./EVChargerDetails";
import { InstallationDetailsForm } from "./InstallationDetails";
import { ConfirmDetails } from "./ConfirmDetails";

import { useEffect, useState } from "react";

/*
 *   This page is used by customers who wish to
 *   get a quote for a charger install at their
 *   home, business etc.
 */

//the first step, the customer enters their details
export const CUSTOMER_DETAILS = 1;
/*
 *   Next, the customer must select which ev
 *   charger they want to installed.
 */
export const EV_CHARGER_SELECTION = 2;
/*
 *   Next the customer must enter information
 *   about where the charger will be installed
 */
export const INSTALLATION_DETAILS = 3;
/*
 *   Finally, the customer will confirm
 */
export const CONFIRM_DETAILS = 4;
/*
 *   The proccess is complete, the
 *   customer is advised they will be
 *   contacted in due time.
 */
export const PROCESS_COMPLETE = 5;

export const QuoteForm = () => {
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState(undefined);
  useEffect(() => {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load();

    // Get the visitor identifier when you need it.
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        setUserId(visitorId);
        createQuotationRequest(visitorId);
      });
  }, []);

  var body;

  switch (step) {
    case CUSTOMER_DETAILS: {
      body = (
        <UserDetailsForm
          visitorId={userId}
          onNextStep={(details) => {
            setStep(step + 1);
          }}
        />
      );
      break;
    }
    case EV_CHARGER_SELECTION: {
      body = (
        <EVChargerDetails
          visitorId={userId}
          onNextStep={(details) => {
            setStep(step + 1);
          }}
          onBack={() => {
            setStep(step - 1);
          }}
        />
      );
      break;
    }
    case INSTALLATION_DETAILS: {
      body = (
        <InstallationDetailsForm
          visitorId={userId}
          onNextStep={(details) => {
            setStep(step + 1);
          }}
          onBack={(details) => {
            setStep(step - 1);
          }}
        />
      );
      break;
    }
    case CONFIRM_DETAILS: {
      body = (
        <ConfirmDetails
          visitorId={userId}
          onSubmit={(details) => {
            setStep(step + 1);
          }}
          onBack={(details) => {
            setStep(step - 1);
          }}
        />
      );
      break;
    }
    case PROCESS_COMPLETE: {
      body = (
        <div className="m-auto">
          <h1>Thank you for your request</h1>
          <p>We will be in touch shortly to discuss your request.</p>
        </div>
      );
      break;
    }
  }

  return (
    <div className="flex flex-row bg-grey h-screen">
      <QuoteStepper step={step} />
      {body}
    </div>
  );
};

async function createQuotationRequest(visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type : 'zappi',
    }),
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/createQuotationRequest`,
    requestOptions
  );
}
