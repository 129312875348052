import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { CgClose } from "react-icons/cg";

import { SelectionTile, updateQuotationRequest } from "../SelectionTile";

/*
 *  This file contains questions to the user
 *  about their home such as:
 *
 *  Do they have an existing MyEnergi Charger?
 */

const EXISTING_CHARGER = 1;
const NO_EXISTING_CHARGER = 2;

const TILES = [
  {
    message: "I have a MyEnergi charger.",
    id: EXISTING_CHARGER,
    icon: <FaCheckCircle size={50} />,
  },
  {
    message: "I don't have a MyEnergi charger",
    id: NO_EXISTING_CHARGER,
    icon: <CgClose size={50} />,
  },
];

export const ExistingCharger = ({ onNextStep, onBack, visitorId }) => {
  const [existingCharger, updateExistingCharger] = useState(null);
  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">Do you already have a MyEnergi charger?</h1>
      <div className="flex flex-row flex-wrap">
        {TILES.map((tile) => (
          <SelectionTile
            key={tile.id}
            onClick={async () => {
              updateExistingCharger(tile.id);
              await updateQuotationRequest(visitorId, "existingChargerUpdate", {
                existingCharger: tile.id,
              });
              onNextStep();
            }}
            selected={existingCharger === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postPowerTypeSelection(powerTypeSelection, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "powerTypeSelectionUpdate",
      powerTypeSelection: powerTypeSelection,
    }),
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
