import { Routes, Route } from "react-router-dom";

import { Auth } from "./pages/admin/Auth";
import { CustomerLanding, Redirect } from "./pages/customer/LandingPage";
import { QuoteForm } from "./pages/customer/zappi/QuoteForm";
import { EddiQuoteForm } from "./pages/customer/eddi/EddiQuoteForm";
import { PolestarOffer } from "./pages/complementary/PolestarOffer";
import { ZagameOffer } from "./pages/complementary/ZagameOffer";
import {
  ClaimQR,
  ClaimPolestar,
  ClaimInstall,
} from "./pages/installer/Installation";
import { CreateCompany } from "./pages/complementary/CreateCompany";
import { GenericComplementary } from "./pages/complementary/GenericComplementary";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<Redirect />} />
        <Route path="get-a-quote" element={<CustomerLanding />} />
        <Route path="get-a-quote/install-eddi" element={<EddiQuoteForm />} />
        <Route path="get-a-quote/install-zappi" element={<QuoteForm />} />
        <Route path="admin-dashboard/*" element={<Auth />} />
        <Route path="polestar-offer" element={<PolestarOffer />} />
        <Route path="zagame-offer" element={<ZagameOffer />} />
        <Route path="create-partnership" element={<CreateCompany />} />
        <Route path="complementary/:slug" element={<GenericComplementary />} />
        {/* <Route
          path="claim-polestar-install/:slug"
          element={<ClaimInstall polestar={true} />}
        />
        <Route path="claim-install/:slug" element={<ClaimInstall />} /> */}
      </Routes>
    </div>
  );
};

export default App;

//<Route path="get-a-quote/install-zappi" element={<CustomerLanding />} />
//<Route path="get-a-quote/install-eddi" element={<CustomerLanding />} />
