import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { FAB } from "../../components/FAB";
import { Modal } from "../../components/Modal";
import { Toolbar } from "../../components/Toolbar";
import { InstallerEnrollmentForm } from "../../components/InstallerEnrollment";

/*
 *  Used by admin to manage installers.
 */
export const InstallerManager = () => {
  const [showInstallerForm, updateShowInstallerForm] = useState(false);
  const [installerList, updateInstallerList] = useState([]);
  const [shouldReload, setShouldReload] = useState(true);

  useEffect(async () => {
    if (!shouldReload) {
      return;
    }
    var result = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/installerList`
    );
    var json = await result.json();
    updateInstallerList(json);
    console.log(`Updated installers!`);
    setShouldReload(false);
  }, [shouldReload]);

  return (
    <div>
      <Toolbar />
      <FAB onClick={() => updateShowInstallerForm(true)} />
      <Modal
        isOpen={showInstallerForm}
        onClose={() => {
          updateShowInstallerForm(false);
          setShouldReload(true);
        }}
        title="Enrol installer"
      >
        <InstallerEnrollmentForm />
      </Modal>
      <InstallerList
        installers={installerList}
        onReload={() => setShouldReload(true)}
      />
    </div>
  );
};

const InstallerList = ({ installers, onReload }) => {
  return (
    <div>
      <div className="flex flex-row">
        <h1 className="mt-2 ml-2 font-bold">Installers</h1>
        <h1 className="mt-2 ml-auto font-bold mr-4">
          * All ranges have been forcibly adjusted to 200km
        </h1>
      </div>

      {installers.map((x) => (
        <InstallerTile
          data={x}
          onDelete={async () => {
            try {
              var result = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/deleteInstaller?id=${x._id}`
              );
              const resultText = await result.text();
              alert(resultText);
            } catch (err) {
              alert(`Error deleting installer: ${err}`);
            }
            onReload();
          }}
        />
      ))}
    </div>
  );
};

const InstallerTile = ({ data, onDelete }) => {
  return (
    <div className="m-1 border rounded p-2 flex flex-row" key={data._id}>
      <div className="flex flex-col">
        <h1>
          {data.company_name} - {data.postcode}
        </h1>
        <p>Range: {data.range}km</p>
      </div>
      <button
        onClick={() => {
          confirmAlert({
            title: `Permanently delete ${data.company_name}`,
            message: `Are you sure to delete ${data.company_name}?`,
            buttons: [
              {
                label: "Yes",
                onClick: () => onDelete(),
              },
              {
                label: "No",
              },
            ],
          });
        }}
        className="rounded text-white ml-auto bg-red-600 p-2"
      >
        Delete
      </button>
    </div>
  );
};
