import { FaPlus } from "react-icons/fa";

export const FAB = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed right-8 bottom-8 text-white flex flex-row px-4 py-2 bg-green-500 rounded-xl items-center border-2"
    >
      <FaPlus />
      <p className="ml-2 text-xl">Add Installer</p>
    </button>
  );
};
