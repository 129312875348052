import { Link } from "react-router-dom";

import zappi from "../../assets/T2_T_W.png";
import eddi from "../../assets/EDDI.png";

const INSTALLATION_OPTIONS = [
  {
    name: "Install Zappi",
    id: "ZAPPI",
    img: zappi,
    link: "install-zappi",
  },
  {
    name: "Install Eddi",
    id: "EDDI",
    img: eddi,
    link: "install-eddi",
  },
];

export const Redirect = () => {
  window.location.assign("/get-a-quote")
}

/*
 *  User is directed here when they navigate to
 *  get-a-quote. They have the option to select
 *  either a zappi install, a eddi install or
 *  both.
 */
export const CustomerLanding = () => {
  return (
    <div className="h-screen">
      <h1 className="customer-landing-text w-full text-center mt-4 text-4xl">
        Get a quote for installation of a MyEnergi device
      </h1>
      <div className="flex gap-8 bg-white justify-center items-center md:overflow-hidden md:flex-row flex-col h-full my-8 md:my-0">
        {INSTALLATION_OPTIONS.map((x) => (
          <InstallationOption option={x} />
        ))}
      </div>
    </div>
  );
};

const InstallationOption = ({ option }) => {
  var installationOptionStyle =
    "w-3/4 md:w-1/3 h-3/4 border rounded-xl my-auto flex flex-col p-4 items-center";
  return (
    <Link to={option.link} className={installationOptionStyle}>
      <img className="mt-8" src={option.img} />
      <h1 className="mt-auto text-2xl font-bold customer-landing-text">
        {option.name}
      </h1>
    </Link>
  );
};
