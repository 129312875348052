import {
  FaHouseUser,
  FaCar,
  FaRoad,
  FaWarehouse,
  FaCarAlt,
} from "react-icons/fa";
import { useState } from "react";

const PARKING_DRIVE = 1;
const PARKING_ATTACHED_INTEGRATED_GARAGE = 2;
const PARKING_DETACHED_GARAGE = 3;
const PARKING_ALLOCATED_PARKING_SPACE = 4;
const PARKING_OTHER = 5;

//The ties that a user is able to select as a parking type
const TILES = [
  {
    message: "Driveway",
    id: PARKING_DRIVE,
    icon: <FaCar size={50} />,
  },
  {
    message: "Attached integrated garage",
    id: PARKING_ATTACHED_INTEGRATED_GARAGE,
    icon: <FaHouseUser size={50} />,
  },
  {
    message: "Detached garage",
    id: PARKING_DETACHED_GARAGE,
    icon: <FaWarehouse size={50} />,
  },
  {
    message: "Allocated parking space",
    id: PARKING_ALLOCATED_PARKING_SPACE,
    icon: <FaRoad size={50} />,
  },
];

export const ParkingInformation = ({ onNextStep, onBack, visitorId }) => {
  const [selectedParking, updateSelectedParking] = useState(null);
  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">What kind of off road parking do you have?</h1>
      <div className="flex flex-row flex-wrap mt-2">
        {TILES.map((tile) => (
          <ParkingTile
            key={tile.id}
            onClick={async () => {
              updateSelectedParking(tile.id);
              await postParkingSelection(tile.id, visitorId);
              onNextStep();
            }}
            selected={selectedParking === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postParkingSelection(parkingSelection, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "parkingSelectionUpdate",
      parkingSelection: parkingSelection,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
  console.log(result);
}

const ParkingTile = ({ message, icon, onClick, selected }) => {
  const [hover, setHover] = useState(false);

  var textColor = "";
  var borderColor = "";

  if (hover || selected === true) {
    textColor = "text-primary";
    borderColor = "border-primary shadow-lg";
  }

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-48 h-48 border-2 rounded p-8 m-2 items-center flex flex-col ${borderColor}`}
    >
      <div className={`${textColor}`}>{icon}</div>
      <h1>{message}</h1>
    </button>
  );
};
