import { useEffect, useState } from "react";
import { Toolbar } from "../../components/Toolbar";
import { QuotationList } from "./Quotations";

export const AdminDashboard = ({ params }) => {
  const [quotationRequests, setQuotationRequests] = useState([]);

  console.log(params);

  useEffect(async () => {
    try {
      var result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/listQuoteRequests`
      );
      var json = await result.json();
      if (!json) {
        return;
      }
      setQuotationRequests(json);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <div>
      <Toolbar />
      <QuotationList quotationRequests={quotationRequests} />
    </div>
  );
};
