import { Link } from "react-router-dom";
import { useState } from "react";

export const QuotationList = ({ quotationRequests = [] }) => {
  return (
    <div className="m-2">
      {quotationRequests.length === 0 && <h1 className="text-2xl ml-2">Loading Quotation Requests...</h1>}
      {quotationRequests
        .filter((x) => x.userInfo)
        .sort((a,b) => {
          if (!b.timestamp) return -1

          else return new Date(b.timestamp) - new Date(a.timestamp)
        })
        .map((quotationRequest) => (
          <QuotationDetail quotationRequest={quotationRequest} />
        ))}
    </div>
  );
};

export const QuotationDetail = ({ quotationRequest }) => {
  if (!quotationRequest) {
    return <h1>Error!</h1>;
  }

  return (
    <Link to={`${quotationRequest._id}`}>
      <div
        className="m-2 flex flex-row border rounded p-2 mt-2 bg-white hover:bg-gray-100"
        key={quotationRequest._id}
      >
        <div className="w-1/4">
          <p>
            {quotationRequest?.userInfo?.firstName} {quotationRequest?.userInfo?.lastName}{" ~ "}
            {quotationRequest?.userInfo?.postcode}
          </p>
          <p>{quotationRequest?.userInfo?.email}</p>
        </div>
        <div className="w-1/4">From: {quotationRequest.timestamp ? new Date(quotationRequest.timestamp).toLocaleDateString() : "N/A"}</div>
        <div className="w-1/4">Requested: {quotationRequest?.type || "Unknown"}</div>
        <div className="w-1/4">Installers in Range: {quotationRequest?.installerMatches?.length}</div>
      </div>
    </Link>
  );
};
