import { FaWindowClose } from "react-icons/fa";

export const Modal = ({ isOpen, children, title = "", onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="p-4">
            <ModalHeader title={title} onClose={onClose} />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalHeader = ({ title, onClose }) => (
  <div className="flex flex-row">
    <h1 className="text-2xl">{title}</h1>
    <button className="ml-auto" onClick={onClose}>
      <FaWindowClose size={28} color="red" />
    </button>
  </div>
);
