import { useState } from "react";

const CABLE_OPTIONS = [
  {
    id: "0-10M",
    message: "0 - 10m",
  },
  {
    id: "10-15M",
    message: "10 - 15m",
  },
  {
    id: "15-20M",
    message: "15 - 20m",
  },
  {
    id: "20-25M",
    message: "20 - 25m",
  },
  {
    id: "25M_",
    message: "25m +",
  },
];

export const CablerunSelector = ({ onNextStep, onBack, visitorId, isZappi }) => {
  const [selectedFuserating, updateSelectedFuserating] = useState(null);

  var heading;
  if (isZappi) {
    heading = "What will be the estimated cable run from your consumer unit to the proposed EV charger location?*"
  } else {
    heading = "What will be the estimated cable run from your consumer unit to the proposed Eddi location?*"
  }
  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">
        {heading}
      </h1>
      <div className="flex flex-row flex-wrap mt-2">
        {CABLE_OPTIONS.map((fuse) => (
          <CableOption
            onClick={async () => {
              updateSelectedFuserating(fuse.id);
              await postCableRunSelection(fuse.id, visitorId);
              onNextStep();
            }}
            selected={selectedFuserating === fuse.id}
            message={fuse.message}
            showQuestion={fuse.id === "DONT_KNOW"}
          />
        ))}
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary mt-16"
          onClick={async () => {
            //validate user input
            await postCableRunSelection(selectedFuserating.id, visitorId);
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary mt-16"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

const CableOption = ({ message, onClick, selected }) => {
  const [hover, setHover] = useState(false);

  var textColor = "";
  var borderColor = "";

  if (hover || selected === true) {
    textColor = "text-primary";
    borderColor = "border-primary shadow-lg";
  }

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-48 h-12 border-2 rounded p-2 m-2 items-center flex flex-col ${borderColor}`}
    >
      <h1 className="font-bold">{message}</h1>
    </button>
  );
};

async function postCableRunSelection(cableRun, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "cableRunUpdate",
      cableRun: cableRun,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
