import { useState, useEffect } from "react";
import myenergi from "../../assets/myenergi-dark.png";
import zappi from "../../assets/zappi.png";
import logo from "../../assets/myenergi_grey.png";

export const CreateCompany = ({}) => {
  const [formDetails, updateFormDetails] = useState();
  const [authorized, updateAuthorized] = useState(false);
  const [submitClicked, updateClicked] = useState(false);
  const [authKey, updateAuthKey] = useState("");

  const resetForm = () => {
    document.getElementById("ZappiForm").reset();
  };

  var enableSubmit = false;
  useEffect(() => {
    enableSubmit = canSubmit(formDetails);
  }, [formDetails]);

  if (authorized) {
    var body = (
      <div className="polestar-width p-8 mx-auto center flex flex-col border border-black bg-white">
        <h1 className="mx-auto polestar-fon fw-500 text-4xl ">
          Add Partnered Company
        </h1>
        <hr className="w-full mx-auto my-6" />
        <form id="ZappiForm" className="mx-auto flex flex-col gap-y-4 w-full">
          <div className="flex flex-row mx-auto gap-x-12">
            <FormField
              title="Company Name *"
              onChange={(e) => {
                updateFormDetails({
                  ...formDetails,
                  name: e.target.value,
                });
              }}
            />
            <div className="flex flex-col">
              <label className="polestar-font text-black">
                Installer Branch
              </label>
              <select
                className="p-2 border-b border-black w-72 polestar-dark"
                placeholder="Select an installer"
                onChange={(e) =>
                  updateFormDetails({ ...formDetails, branch: e.target.value })
                }
              >
                <option value="" label="Select installer" />
                <option value="MyEnergi" label="MyEnergi" />
                <option value="EVolution" label="EVolution" />
              </select>
            </div>
          </div>
          <div className="flex flex-row mx-auto gap-x-12">
            <FormField
              title="Auth Key *"
              onChange={(e) => {
                updateFormDetails({
                  ...formDetails,
                  authKey: e.target.value,
                });
              }}
            />
          </div>
        </form>
        <hr className="w-full mx-auto my-6" />
        <button
          className="polestar-font w-36 mx-auto p-3 text-white bg-black"
          onClick={async () => {
            if (submitClicked) {
              console.log("Form is already submitting!");
              return;
            } else if (enableSubmit) {
              updateClicked(true);
              var res = await uploadCompany(formDetails);
              alert(res);

              updateFormDetails({});
              resetForm();
              updateClicked(false);
            } else {
              alert("Please fill out the required fields.");
            }
          }}
        >
          {submitClicked ? "Submitting..." : "Submit"}
        </button>
      </div>
    );
  } else {
    var body = (
      <div className="p-6 mx-auto center flex flex-col border border-black bg-white">
        <h1 className="polestar-font mx-auto text-xl mb-4">
          Authorisation Key:
        </h1>
        <input
          className="p-2 border-b border-black w-72 polestar-dark"
          type={"password"}
          onChange={(e) => updateAuthKey(e.target.value)}
          onKeyDown={(e) => {
            // Enter pressed?
            if (e.key == "Enter") {
              if (authKey === "Russisawesome1!") {
                console.log("AUTHORIZED");
                updateAuthorized(true);
              } else {
                alert("Invalid authorization key.");
              }
            }
          }}
        />
        <button
          className="polestar-font w-36 mx-auto p-3 mt-6 text-white bg-black"
          onClick={async () => {
            if (authKey === "Russisawesome1!") {
              console.log("AUTHORIZED");
              updateAuthorized(true);
            } else {
              alert("Invalid authorization key.");
            }
          }}
        >
          Enter
        </button>
      </div>
    );
  }

  return (
    <div className="polestar-bg-colour h-screen w-full">
      <div className="fixed right-0 top-24 h-96 z-0">
        <img src={zappi} className="absolute z-20 ml-2 mt-8 h-80" />
        <img src={logo} className="mx-auto ml-6 z-10 h-96" />
      </div>
      <div className="flex flex-row fixed top-4 left-4">
        <img src={myenergi} className="h-32 p-4 my-auto" />
      </div>
      {body}
    </div>
  );
};

const FormField = ({ title, onChange, dateformat = false }) => {
  return (
    <div className="flex flex-col">
      <label className="polestar-font text-black">{title}</label>
      <input
        className="p-2 border-b border-black w-72 polestar-dark"
        type={dateformat ? "date" : "text"}
        onChange={onChange}
      />
    </div>
  );
};

/*
 *  Send a Compementary Zappi request to the rest server.
 */
export async function uploadCompany(form_details) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...form_details,
    }),
  };
  console.log("SENDING!");
  var res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/createPartnerCompany`,
    requestOptions
  );
  return await res.text();
}

function canSubmit(form_details) {
  return Boolean(!!form_details?.name && !!form_details?.branch);
}
