import { useState } from "react";
import { FaPlug, FaBolt, FaQuestion, FaSun } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai"

import { SelectionTile, updateQuotationRequest } from "../SelectionTile";

/*
 *  This file contains questions to the user
 *  about their home such as:
 *
 *  Do they have a single phase power supply?
 *  Do they have a triple phase power supply?
 */

const HAS_SOLAR =  [
    {
      message: "I do have solar",
      id: true,
      icon: <FaSun size={50} />,
    },
    {
      message: "I don't have solar",
      id: false,
      icon: <AiOutlineCloseCircle size={50} />,
    },
  ]

const SINGLE_PHASE = 1;
const TRIPLE_PHASE = 2;
const OTHER = 3;

const TILES = [
  {
    message: "1 phase power",
    id: SINGLE_PHASE,
    icon: <FaPlug size={50} />,
  },
  {
    message: "3 phase power",
    id: TRIPLE_PHASE,
    icon: <FaBolt size={50} />,
  },
  {
    message: "Other / don't know",
    id: OTHER,
    icon: <FaQuestion size={50} />,
  },
];

export const SolarDetails = ({ onNextStep, onBack, visitorId }) => {
  const [hasSolar, updateHasSolar] = useState(null);

  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Do you have solar installed?
      </h1>
      <div className="flex flex-row flex-wrap mt-8">
      {HAS_SOLAR.map((tile) => (
          <SelectionTile
            key={tile.id}
            onClick={async () => {
              updateHasSolar(tile.id);
            }}
            selected={hasSolar === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
    </div>
      {hasSolar && <>
        <h1 className="my-2 ml-1">What power type is your solar?</h1>
        <div className="flex flex-row flex-wrap">
        {TILES.map((tile) => (
        <SelectionTile
            key={tile.id}
            onClick={async () => {
            await updateQuotationRequest(visitorId, "hasSolarUpdate", {hasSolar})
            await updateQuotationRequest(visitorId, "solarTypeUpdate", {solarType: tile.id})
            onNextStep();
            }}
            selected={false}
            message={tile.message}
            icon={tile.icon}
        />
        ))}
        </div>
      </>}
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

