import { useState } from "react";

/*
 *  This page is used to enroll trusted
 *  installers onto the platform.
 */
export const InstallerEnrollmentForm = ({}) => {
  const [submitting, setSubmitting] = useState(false);

  if (submitting) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          setSubmitting(true);

          var company_name = event.target[0].value;
          var contact_name = event.target[1].value;
          var contact_email = event.target[2].value;
          var postcode = event.target[3].value;
          var range = event.target[4].value;
          var phone_number = event.target[5].value;

          var payload = {
            company_name,
            contact_name,
            contact_email,
            postcode: Number.parseInt(postcode),
            range: Number.parseInt(range),
            phone_number: Number.parseInt(phone_number),
          };

          console.log(`Sending payload:`, payload);

          var serializedBody = JSON.stringify(payload);

          var result = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/enrollInstaller`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Content-Length": serializedBody.length,
              },
              body: serializedBody,
            }
          );

          var message = await result.text();

          alert(message);
          setSubmitting(false);
        }}
      >
        <div key="company_name" className="mt-4 flex flex-col">
          <label className="font bold mb-0.5">Company Name</label>
          <input
            className="border rounded p-2"
            name="company_name"
            type="text"
            placeholder="Company Name"
          />
        </div>
        <div key="contact_name" className="flex flex-col mt-2">
          <label className="font bold mb-0.5">Contact Name</label>
          <input
            className="border rounded p-2"
            name="contact_name"
            type="text"
            placeholder="Contact Name"
          />
        </div>
        <div key="contact_email" className="flex flex-col mt-2">
          <label className="mb-0.5">Contact Email:</label>
          <input
            className="border rounded p-2"
            name="contact_email"
            type="email"
            placeholder="Contact Email"
          />
        </div>
        <div key="postcode" className="flex flex-col mt-2">
          <label className="mb-0.5">Postcode:</label>
          <input
            className="border rounded p-2"
            name="postcode"
            type="number"
            placeholder="Postcode"
          />
        </div>
        <div key="range" className="flex flex-col mt-2">
          <label className="mb-0.5">Range:</label>
          <input
            className="border rounded p-2"
            name="range"
            type="number"
            placeholder="Range"
          />
        </div>
        <div key="phone_number" className="flex flex-col mt-2">
          <label className="mb-0.5">Phone Number:</label>
          <input
            className="border rounded p-2"
            name="phone_number"
            type="number"
            placeholder="Phone Number"
          />
        </div>
        <hr className="border mt-2" />
        <div className="flex flex-row">
          <button
            className="bg-green-600 text-white py-2 px-4 rounded mt-2"
            type="submit"
          >
            Create installer
          </button>
        </div>
      </form>
    </div>
  );
};
