import { Link } from "react-router-dom";

export const Toolbar = () => {
  return (
    <div className="h-16 flex flex row bg-black items-center">
      <h1 className="text-2xl m-4 text-white">MyEnergi Quote Tool</h1>
      <Link
        to="/admin-dashboard/installers"
        className="text-xl text-white rounded py-2 px-4 hover:bg-white hover:bg-opacity-20"
      >
        Installers
      </Link>
      <Link
        to="/admin-dashboard/quote-requests"
        className="text-xl text-white rounded py-2 px-4 hover:bg-white hover:bg-opacity-20"
      >
        Quotation Requests
      </Link>
    </div>
  );
};
