import { useState } from "react";

import { FaHouseUser, FaWarehouse, FaBuilding } from "react-icons/fa";

/*
 *  This file contains questions to the user
 *  about their home such as:
 *
 *  Do they have a single, double, multi storey dwelling?
 *  Do they have single or triple phase power?
 *  Do they have a home battery?
 *  Do they already have a myenergi charger?
 */

const SINGLE_STOREY = 1;
const DOUBLE_STOREY = 2;
const OTHER = 3;

const TILES = [
  {
    message: "Single storey dwelling",
    id: SINGLE_STOREY,
    icon: <FaHouseUser size={50} />,
  },
  {
    message: "Double storey dwelling",
    id: DOUBLE_STOREY,
    icon: <FaBuilding size={50} />,
  },
  {
    message: "Other",
    id: OTHER,
    icon: <FaWarehouse size={50} />,
  },
];

export const PropertyType = ({ onNextStep, onBack, visitorId }) => {
  const [selectedPropertyType, updateSelectedPropertyType] = useState(null);
  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">What kind of dwelling do you have?</h1>
      <div className="flex flex-row flex-wrap mt-2">
        {TILES.map((tile) => (
          <DwellingTile
            key={tile.id}
            onClick={async () => {
              updateSelectedPropertyType(tile.id);
              await postDwellingSelection(tile.id, visitorId);
              onNextStep();
            }}
            selected={selectedPropertyType === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postDwellingSelection(dwellingSelection, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "dwellingSelectionUpdate",
      dwellingSelection: dwellingSelection,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
  console.log(result);
}

const DwellingTile = ({ message, icon, onClick, selected }) => {
  const [hover, setHover] = useState(false);

  var textColor = "";
  var borderColor = "";

  if (hover || selected === true) {
    textColor = "text-primary";
    borderColor = "border-primary shadow-lg";
  }

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-48 h-40 border-2 rounded p-8 m-2 items-center flex flex-col ${borderColor}`}
    >
      <div className={`${textColor}`}>{icon}</div>
      <h1>{message}</h1>
    </button>
  );
};
