import { FaCheck } from "react-icons/fa";
import {
  CUSTOMER_DETAILS,
  INSTALLATION_DETAILS,
  EV_CHARGER_SELECTION,
  CONFIRM_DETAILS,
} from "./QuoteForm";

import myenergilogo from "../../../assets/myenergilogo.jpeg";

export const ProgressTracker = ({ step }) => {
  switch (step) {
    case CUSTOMER_DETAILS:
      return <div>Enter your details!</div>;
  }
};

export const QuoteStepper = ({ step }) => {
  return (
    <div className="flex-shrink h-full bg-black px-3 pt-4 w-1/4">
      <div>
        <img src={myenergilogo} />
      </div>
      <div className="flex flex-col items-center mt-8">
        <Step
          number={CUSTOMER_DETAILS}
          message="Your details"
          selected={step >= CUSTOMER_DETAILS}
        />
        <Step
          number={EV_CHARGER_SELECTION}
          message="EV Charger details"
          selected={step >= EV_CHARGER_SELECTION}
        />
        <Step
          number={INSTALLATION_DETAILS}
          message="Installation details"
          selected={step >= INSTALLATION_DETAILS}
        />
        <Step
          number={CONFIRM_DETAILS}
          message="Confirm details"
          selected={step >= CONFIRM_DETAILS}
          last={true}
        />
      </div>
    </div>
  );
};

const Step = ({ number, message, selected, last }) => {
  var textColor = "text-white";
  var borderColor = "border-white";
  var divider = null;

  if (selected) {
    textColor = "text-primary";
    borderColor = "border-primary";
  }

  if (last !== true) {
    divider = (
      <div
        className={`ml-6 mt-2 ${
          selected ? "progress-line-green" : "progress-line-white"
        }`}
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row mt-2">
        <div className={`p-2 border rounded-full ${borderColor} w-12`}>
          <FaCheck className={textColor} size={30} />
        </div>
        <div className="ml-6">
          <p className={textColor}>Step {number}</p>
          <h1 className="w-52 text-white font-bold">{message}</h1>
        </div>
      </div>
      {divider}
    </div>
  );
};
