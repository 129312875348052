import { useState } from "react";
import { FaBolt, FaQuestion, FaQuestionCircle } from "react-icons/fa";

const FUSE_OPTIONS = [
  {
    id: "50_AMPS",
    message: "50 Amps",
  },
  {
    id: "63_AMPS",
    message: "63 Amps",
  },
  {
    id: "100_AMPS",
    message: "100 Amps",
  },
  {
    id: "DONT_KNOW",
    message: "Don't know",
  },
];

export const FuseratingSelector = ({ onNextStep, onBack, visitorId }) => {
  const [selectedFuserating, updateSelectedFuserating] = useState(null);
  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">
        Please select your main fuse rating (this normally found near your
        electricity meter).*
      </h1>
      <div className="flex flex-row flex-wrap mt-2">
        {FUSE_OPTIONS.map((fuse) => (
          <FuseTile
            onClick={async () => {
              updateSelectedFuserating(fuse.id);
              await postFuseRatingDetails(fuse.id, visitorId);
              onNextStep();
            }}
            selected={selectedFuserating === fuse.id}
            message={fuse.message}
            showQuestion={fuse.id === "DONT_KNOW"}
          />
        ))}
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={async () => {
            //validate user input
            await postFuseRatingDetails(selectedFuserating.id, visitorId);
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

const FuseTile = ({ message, onClick, selected, showQuestion }) => {
  const [hover, setHover] = useState(false);
  const IMAGE_SIZE = 60;

  var textColor = "";
  var borderColor = "";

  if (hover || selected === true) {
    textColor = "text-primary";
    borderColor = "border-primary shadow-lg";
  }

  var image = <FaBolt className={textColor} size={IMAGE_SIZE} />;

  if (showQuestion) {
    image = <FaQuestionCircle className={textColor} size={IMAGE_SIZE} />;
  }
  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-48 h-40 border-2 rounded p-8 m-2 items-center flex flex-col ${borderColor}`}
    >
      {image}
      <h1 className="font-bold mt-8">{message}</h1>
    </button>
  );
};

async function postFuseRatingDetails(fuseRatingDetails, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "fuseRatingUpdate",
      mainFuseRating: fuseRatingDetails,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
