import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { AdminDashboard } from "./Dashboard";
import { InstallerManager } from "./InstallerManager";
import { validAccessCode } from "./Signin";

export const Auth = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const accessCode = prompt("Access code:");

    if (accessCode === validAccessCode) {
      setAuthenticated(true);
    }
  }, []);

  return authenticated ? (
    <Routes>
      <Route path="installers" element={<InstallerManager />} />
      <Route path="*" element={<AdminDashboard />} />
    </Routes>
  ) : (
    <div>You don't have access to this page</div>
  );
};
