import { useState } from "react";
import { ExtraInstallationDetails } from "./ExtraInstallationDetails";
import { ParkingInformation } from "./ParkingInformation";
import { Fileupload } from "./PhotoUpload";
import { FuseratingSelector } from "./FuseratingSelector";
import { CablerunSelector } from "./CableRun";
import { FinalComments } from "./Finalcomments";
import { PropertyType } from "./HomeDetails";
import { PowerType } from "./PowerType";
import { HomeBattery } from "./HomeBattery";
import { ExistingCharger } from "./ExistingCharger";
import { SolarDetails } from "./SolarDetails";

/*
 *   Sub steps for installation
 *   include:
 *
 *   1. Select parking type
 *   2. Upload images for installer
 *   3. Provide extra details as a comment
 */

export const InstallationDetailsForm = ({ onNextStep, onBack, visitorId }) => {
  const [substep, setSubstep] = useState(1);

  const substeps = [
    <ParkingInformation
      visitorId={visitorId}
      onBack={onBack}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <PropertyType
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <PowerType
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <HomeBattery
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <ExistingCharger
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <Fileupload
      subtext={
        "Please upload a photograph of where your car will be parked when charging and where you would like the EV charger installed."
      }
      visitorId={visitorId}
      type="parkingImage"
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <ExtraInstallationDetails
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <Fileupload
      visitorId={visitorId}
      type="consumerUnitImage"
      subtext={"Please upload a photo of your consumer unit."}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <Fileupload
      visitorId={visitorId}
      type="mainsMeterImage"
      subtext={
        "Please upload a photograph of mains electricity meter/fuse rating."
      }
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <FuseratingSelector
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <CablerunSelector
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
      isZappi={true}
    />,
    <SolarDetails
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <FinalComments
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={onNextStep}
    />,
  ];

  return substeps[substep - 1];
};
