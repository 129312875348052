import { useState } from "react";
import { FaPlug, FaBolt, FaQuestion } from "react-icons/fa";

import { SelectionTile } from "../SelectionTile";

/*
 *  This file contains questions to the user
 *  about their home such as:
 *
 *  Do they have a single phase power supply?
 *  Do they have a triple phase power supply?
 */

const SINGLE_PHASE = 1;
const TRIPLE_PHASE = 2;
const OTHER = 3;

export const TILES = [
  {
    message: "1 phase power supply",
    id: SINGLE_PHASE,
    icon: <FaPlug size={50} />,
  },
  {
    message: "3 phase power supply",
    id: TRIPLE_PHASE,
    icon: <FaBolt size={50} />,
  },
  {
    message: "Other / don't know",
    id: OTHER,
    icon: <FaQuestion size={50} />,
  },
];

export const PowerType = ({ onNextStep, onBack, visitorId }) => {
  const [selectedPowerType, updateSelectedPowerType] = useState(null);
  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">What kind of power supply do you have?</h1>
      <div className="flex flex-row flex-wrap mt-2">
        {TILES.map((tile) => (
          <SelectionTile
            key={tile.id}
            onClick={async () => {
              updateSelectedPowerType(tile.id);
              await postPowerTypeSelection(tile.id, visitorId);
              onNextStep();
            }}
            selected={selectedPowerType === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postPowerTypeSelection(powerTypeSelection, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "powerTypeSelectionUpdate",
      powerTypeSelection: powerTypeSelection,
    }),
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
