import { useState } from "react";

import T2_T_W from "../../../assets/T2_T_W.png";
import T2_UT_W from "../../../assets/T2_UT_W.png";

//Type 2, tethered, 7kw white with hub
const T2_T_7KW_W_H = "T2_T_7KW_W_H";
//Type 2, untethered, 7kw white with hub
const T2_UT_7KW_W_H = "T2_UT_7KW_W_H";

//Type 2, tethered, 22kw 3 phase white with hub
const T2_T_22KW_3P_W_H = "T2_T_22KW_3P_W_H";
//Type 2, untethered, 22kw 3 phase white with hub
const T2_UT_22KW_3P_W_H = "T2_UT_22KW_3P_W_H";

const CHARGERS = [
  {
    id: T2_T_7KW_W_H,
    message: (
      <h1>
        Single phase, <br /> tethered. (7kW)
      </h1>
    ),
    image: T2_T_W,
    tethered: true,
  },
  {
    id: T2_UT_7KW_W_H,
    message: (
      <h1>
        Single phase, <br /> untethered. (7kW)
      </h1>
    ),
    image: T2_UT_W,
    tethered: false,
  },
  {
    id: T2_T_22KW_3P_W_H,
    message: (
      <h1>
        Triple phase, <br /> tethered. (22kW)
      </h1>
    ),
    image: T2_T_W,
    tethered: true,
  },
  {
    id: T2_UT_22KW_3P_W_H,
    message: (
      <h1>
        Triple phase, <br /> untethered. (22kW)
      </h1>
    ),
    image: T2_UT_W,
    tethered: false,
  },
];

export const EVChargerDetails = ({ onBack, onNextStep, visitorId }) => {
  const [selected, setSelected] = useState(null);

  return (
    <div className="bg-white border rounded overflow-hidden m-4 p-4 pr-8">
      <h1 className="text-5xl">
        Select the EV charger that you would like installed.
      </h1>
      <h1 className="mt-8">
        Which EV charger would you like to get a quote for? (Scroll for 22kw
        options).
      </h1>
      <div className="overflow-x-scroll w-full">
        <div className="flex flex-row">
          {CHARGERS.map((charger) => (
            <ChargerTile
              message={charger.message}
              icon={charger.image}
              onClick={async () => {
                setSelected(charger.id);
                await postChargerSelection(charger.id, visitorId);
                onNextStep();
              }}
              selected={charger.id === selected}
              tethered={charger.tethered}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postChargerSelection(chargerSelection, visitorId) {
  console.log(`Posting charger ${visitorId}`);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "chargerSelectionUpdate",
      chargerSelection: chargerSelection,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
  console.log(result);
}

const ChargerTile = ({ message, icon, onClick, selected, tethered }) => {
  const [hover, setHover] = useState(false);

  var textColor = "";
  var borderColor = "";

  if (hover || selected === true) {
    textColor = "text-primary";
    borderColor = "border-primary shadow-lg";
  }

  return (
    <div className={`border-2 rounded m-2 ${borderColor}`}>
      <button
        className={`w-48 items-center flex flex-col pb-8`}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img className="mt-8 w-32" src={icon} />
        <h1 className={tethered ? `mt-5 ` : "mt-20"}>{message}</h1>
      </button>
    </div>
  );
};
