import { useState } from "react";

export const SelectionTile = ({ message, icon, onClick, selected }) => {
  const [hover, setHover] = useState(false);

  var textColor = "";
  var borderColor = "";

  if (hover || selected === true) {
    textColor = "text-primary";
    borderColor = "border-primary shadow-lg";
  }

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-48 h-44 border-2 rounded p-8 m-2 items-center flex flex-col ${borderColor}`}
      key={message}
    >
      <div className={`${textColor}`}>{icon}</div>
      <h1>{message}</h1>
    </button>
  );
};

/*
 *  Send a quotation request update to the rest server.
 */
export async function updateQuotationRequest(visitorId, type, other) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: type,
      ...other,
    }),
  };
  await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
