import { MdPower, MdPowerOff } from 'react-icons/md';
import { SelectionTile, updateQuotationRequest} from '../SelectionTile';

const BELOW = 1;
const ABOVE = 2;

const TILES = [
    {
      message: "Over 3.68kW",
      id: ABOVE,
      icon: <MdPower size={50} />,
    },
    {
      message: "3.68kW or under",
      id: BELOW,
      icon: <MdPowerOff size={50} />,
    },
  ];

export const WaterHeaterSelector = ({ visitorId, onNextStep, onBack}) => {
    async function nextStep(selected) {
        onNextStep();
        await updateQuotationRequest(
          visitorId,
          "waterHeaterSelectionUpdate",
          {
            "waterHeaterSelection" : selected,
          }
        );
      }

    return (
        <div className="bg-white border rounded w-full m-4 p-4 pr-52">
          <h1 className="text-5xl">
            Let's get some details for your EV charger quote.
          </h1>
          <h1 className="mt-8">What is the kW of your current water heater?</h1>
          <div className="flex flex-row flex-wrap">
            {TILES.map((tile) => (
              <SelectionTile key={tile.id} message={tile.message} icon={tile.icon} onClick={() => nextStep(tile.id)} selected={false}
              />
            ))}
          </div>
          <div>
              Disclaimer: Any water heater over 3.68 kW will require a downgrade in order to install Eddi
          </div>
          <div className="flex flex-row mt-8">
            <button
              className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
              onClick={() => alert("Please select an option")}
            >
              Next Step
            </button>
            <button
              className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
              onClick={() => {
                //validate user input
                onBack();
              }}
            >
              Back
            </button>
          </div>
        </div>
      );
}