import { useState } from "react";
import { TILES } from "./PowerType";

export const ConfirmDetails = ({ onSubmit, onBack, visitorId }) => {
  const [submitting, updateSubmitting] = useState(false);
  const [solarRequested, updateSolarRequested] = useState(false)

  if (submitting) {
    return <h1 className="m-auto">Submitting...</h1>;
  }

  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">Thank you, your application is complete.</h1>
      <div className="flex flex-row mt-4 ml-1">
        <label for="solar" className="text-xl ">Would you like solar with that?</label>
        <input className="ml-2 w-4" type="checkbox" value={solarRequested} id="solar" onClick={() => updateSolarRequested(!solarRequested)}/>
      </div>
      <div className="flex flex-row mt-4">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={async () => {
            updateSubmitting(true);
            //validate user input
            await postSolarRequested(solarRequested, visitorId)
            await postSubmitForm(visitorId);
            updateSubmitting(false);
            onSubmit();
          }}
        >
          Submit form
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postSubmitForm(visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "submissionUpdate",
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}

async function postSolarRequested(solarRequested, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "solarRequestedUpdate",
      solarRequested
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}

async function postSolarType(solarRequested, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "solarTypeUpdate",
      solarRequested
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
