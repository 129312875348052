import { BsSpeedometer2 } from 'react-icons/bs';
import { MdOutlineCable } from 'react-icons/md';
import {AiOutlineBoxPlot} from 'react-icons/ai';
import { useState } from 'react';
import { SelectionTile, updateQuotationRequest } from '../SelectionTile';

const METERBOX = 1;
const WATERPROOF_CLIPBOX = 2;
const UNDERCOVER = 3;

const TILES = [
  {
    message: "Meterbox",
    id: METERBOX,
    icon: <BsSpeedometer2 size={50} />,
  },
  {
    message: "Waterproof Clipbox",
    id: WATERPROOF_CLIPBOX,
    icon: <AiOutlineBoxPlot size={50} />,
  },
  {
    message: "Undercover Location",
    id: UNDERCOVER,
    icon: <MdOutlineCable size={50} />,
  },
];

export const PreferredInstallLocation = ({ onNextStep, onBack, visitorId, }) => {
    const [selectedLocation, updateSelectedLocation] = useState(null);

    async function nextStep(selectedLocation) {
      onNextStep(selectedLocation === METERBOX);
      await updateQuotationRequest(
        visitorId,
        "eddiInstallLocationUpdate",
        {
          "eddiInstallLocation" : selectedLocation,
        }
      );
    }

    return (
      <div className="bg-white border rounded w-full m-4 p-4 pr-52">
        <h1 className="text-5xl">
          Let's get some details for your EV charger quote.
        </h1>
        <h1 className="mt-8">Where would you prefer to install an Eddi device?</h1>
        <div className="flex flex-row flex-wrap">
          {TILES.map((tile) => (
            <SelectionTile key={tile.id} message={tile.message} icon={tile.icon} onClick={() => nextStep(tile.id)} selected={selectedLocation === tile.id}
            />
          ))}
        </div>
        <div>
          Disclaimer: Eddi is not waterproof, so must be installed in a sheltered area.
        </div>
        <div className="flex flex-row mt-8">
          <button
            className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
            onClick={() => alert("Please select an option")}
          >
            Next Step
          </button>
          <button
            className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
            onClick={() => {
              //validate user input
              onBack();
            }}
          >
            Back
          </button>
        </div>
      </div>
    );
  };