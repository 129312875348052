import React from "react";

/*
 *  Some abstract photo upload component,
 *  used to upload photos the customer provides
 *  for their quote request, to the server.
 */
export const Fileupload = ({
  onNextStep,
  onBack,
  subtext,
  visitorId,
  type,
}) => {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  if (loading === true) {
    return <div className="m-auto">Loading...</div>;
  }

  return (
    <div className="bg-white border rounded m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">{subtext}</h1>
      <div className="mt-4">
        <ImagePreview image={selectedImage} />
        <button
          onClick={handleClick}
          className="mt-4 bg-secondary px-4 py-2 border rounded-xl text-white"
        >
          {selectedImage ? "Change Image" : "Upload Image"}
        </button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={async () => {
            setLoading(true);
            await uploadFile(selectedImage, visitorId, type);
            setLoading(false);
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function uploadFile(file, visitorId, type) {
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    body: formData,
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/photoUpload?visitorId=${visitorId}&type=${type}`,
    requestOptions
  );
  console.log(result);
}

const ImagePreview = ({ image }) => {
  if (!image) {
    return null;
  }
  return <img src={URL.createObjectURL(image)} />;
};
