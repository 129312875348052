import { useState } from "react";

export const FinalComments = ({ onNextStep, onBack, visitorId }) => {
  const [finalComments, setFinalComments] = useState("");

  return (
    <div className="bg-white border rounded m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8 text-xl">
        Please provide in your own words details about the installation.
      </h1>
      <h1>
        e.g. Consumer Unit in hall cupboard on external wall near the front of
        the property so cable can run along this wall connecting the charge unit
        to the Consumer Unit.
      </h1>
      <div className="mt-2">
        <textarea
          className="p-2 h-64 w-full border rounded"
          placeholder="Final comments"
          onChange={(e) => setFinalComments(e.target.value)}
        />
      </div>
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={async () => {
            //validate user input
            await postFinalComments(finalComments, visitorId);
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

async function postFinalComments(finalComments, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "finalCommentsUpdate",
      finalComments: finalComments,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
}
