import { useState } from "react";
import { FaBatteryFull, FaBolt, FaLongArrowAltRight, FaQuestion } from "react-icons/fa";
import { AiOutlineSwap } from "react-icons/ai"

import { SelectionTile, updateQuotationRequest } from "../SelectionTile";

/*
 *  This file contains questions to the user
 *  about their home such as:
 *
 *  Do they have a home battery?
 */
const HAS_BATTERY = 1;
const NO_BATTERY = 2;

const TILES = [
  {
    message: "I have a home battery",
    id: HAS_BATTERY,
    icon: <FaBatteryFull size={50} />,
  },
  {
    message: "I don't have a home battery",
    id: NO_BATTERY,
    icon: <FaBolt size={50} />,
  },
];


const AC = 0;
const DC = 1;
const DONT_KNOW = 2;
const POWER_DELIVERY_TILES = [
  {
    message: "AC",
    id: AC,
    icon: <AiOutlineSwap size={50}/>
  },
  {
    message: "DC",
    id: DC,
    icon: <FaLongArrowAltRight size={50}/>
  },
  {
    message: "Don't know",
    id: DONT_KNOW,
    icon: <FaQuestion size={50} />
  }
]

export const HomeBattery = ({ onNextStep, onBack, visitorId }) => {
  const [selectedHomeBattery, updateSelectedHomeBattery] = useState(null);
  const [selectedType, updateSelectedType] = useState(null)

  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">
        Let's get some details for your EV charger quote.
      </h1>
      <h1 className="mt-8">
        Do you have a home battery? (Telsa powerwall etc.)
      </h1>
      <h1>If unsure, select no.</h1>
      <div className="flex flex-row flex-wrap">
        {TILES.map((tile) => (
          <SelectionTile
            key={tile.id}
            onClick={async () => {
              updateSelectedHomeBattery(tile.id);
              
            }}
            selected={selectedHomeBattery === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
      </div>
      {selectedHomeBattery && <>
        <h1>
          Is it AC or DC?
        </h1>
        <div className="flex flex-row flex-wrap">
        {POWER_DELIVERY_TILES.map((tile) => (
          <SelectionTile
            key={tile.id}
            onClick={async () => {
              updateSelectedType(tile.id);
              await updateQuotationRequest(
                visitorId,
                "homeBatterySelectionUpdate",
                {
                  homeBatterySelection: tile.id,
                }
              );
              await updateQuotationRequest(
                visitorId,
                "homeBatteryTypeUpdate",
                {
                  homeBatteryType: tile.id,
                }
              );
              onNextStep();
            }}
            selected={selectedType === tile.id}
            message={tile.message}
            icon={tile.icon}
          />
        ))}
        </div>
      </>}
      <div className="flex flex-row mt-8">
        <button
          className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary"
          onClick={() => {
            //validate user input
            onNextStep();
          }}
        >
          Next Step
        </button>
        <button
          className="ml-2 border-2 border-secondary rounded-xl px-4 py-2 bg-white text-secondary"
          onClick={() => {
            //validate user input
            onBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};
