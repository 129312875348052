import { useState } from "react";

const COUNTRY_TO_CODE = {
  Australia: "+61 ",
  "New Zealand": "+64 ",
};

export const UserDetailsForm = ({ onNextStep, visitorId }) => {
  const [userDetails, setUserDetails] = useState({});
  const [country, setCountry] = useState("Australia");

  if (!visitorId) {
    return <div>Loading...</div>;
  }

  var body = (
    <form className="m-2 mt-8 w-full">
      <div className="flex flex-row">
        <div className="">
          <h1>Country:</h1>
          <select
            className="py-2 px-1 w-32 border rounded"
            defaultValue={"Australia"}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          >
            <option value={"Australia"}>Australia</option>;
            <option value={"New Zealand"}>New Zealand</option>;
          </select>
        </div>
      </div>
      <div className="flex flex-row mt-2">
        <div>
          <h1>First Name</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="First name"
            onChange={(e) => {
              setUserDetails({ ...userDetails, firstName: e.target.value });
            }}
          />
        </div>

        <div className="ml-2">
          <h1>Last Name</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="Last name"
            onChange={(e) => {
              setUserDetails({ ...userDetails, lastName: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-row">
        <div>
          <h1>Email</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="Email"
            onChange={(e) => {
              setUserDetails({ ...userDetails, email: e.target.value });
            }}
          />
        </div>
        <div className="ml-2">
          <h1>Phone Number</h1>
          <div className="flex flex-row">
            <div className="py-1 px-2 border rounded emulate-input">
              {COUNTRY_TO_CODE[country]}
              <input
                className="w-36 py-1"
                type="text"
                placeholder="000 000 000"
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    phoneNumber: COUNTRY_TO_CODE[country] + e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex flex-row">
        <div>
          <h1>Street Number</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="Street Number"
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                streetNumber: e.target.value,
              });
            }}
          />
        </div>
        <div className="ml-2">
          <h1>Street Name</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="Street Name"
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                streetName: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-row">
        <div>
          <h1>Town / City</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="Town / City"
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                city: e.target.value,
              });
            }}
          />
        </div>
        <div className="ml-2">
          <h1>Postcode</h1>
          <input
            className="p-2 border rounded"
            type="text"
            placeholder="Postcode"
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                postcode: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <button
        className="border-2 border-primary rounded-xl px-4 py-2 bg-white text-primary mt-16"
        onClick={async (e) => {
          e.preventDefault();
          //validate user input
          var validationResult = validateUserDetails(userDetails);

          if (validationResult !== true) {
            alert(`Please fill out the field ${validationResult}`);
            return;
          }

          await postUserInfo({ ...userDetails, country }, visitorId);

          onNextStep();
        }}
      >
        Next Step
      </button>
    </form>
  );

  return (
    <div className="bg-white border rounded w-full m-4 p-4 pr-52">
      <h1 className="text-5xl">Let's start with some details about you.</h1>
      <h1 className="mt-8">
        Thank you for your interest in our services! In order to provide you
        with an accurate quote, the installers in our network will require some
        specific information about your EV and the property you want your home
        charger installed at. We will be asking you to provide some photos as
        well, so please complete the following forms as accurately as you can.
      </h1>
      {body}
    </div>
  );
};

//send the user info to database
async function postUserInfo(userDetails, visitorId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      visitorId: visitorId,
      type: "userInfoUpdate",
      userDetails: userDetails,
    }),
  };

  var result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/updateQuotationRequest`,
    requestOptions
  );
  console.log(result);
}

function validateUserDetails(userDetails) {
  var firstName = userDetails.firstName;

  if (!firstName || firstName.length === 0) {
    return "first name";
  }

  var lastName = userDetails.lastName;

  if (!lastName || lastName.length === 0) {
    return "last name";
  }

  var email = userDetails.email;

  if (!email || email.length === 0) {
    return "email";
  }

  var phoneNumber = userDetails.phoneNumber;

  if (!phoneNumber || phoneNumber.length === 0) {
    return "phone number";
  }

  var streetNumber = userDetails.streetNumber;

  if (!streetNumber || streetNumber.length === 0) {
    return "street number";
  }

  var streetName = userDetails.streetName;

  if (!streetName || streetName.length === 0) {
    return "street name";
  }

  var city = userDetails.city;

  if (!city || city.length === 0) {
    return "city";
  }

  var postcode = userDetails.postcode;

  if (!postcode || postcode.length === 0) {
    return "post code";
  }

  return true;
}
