import { useState } from "react";

import { FinalComments } from "../zappi/Finalcomments";
import { PropertyType } from "../zappi/HomeDetails";
import { SelectionTile, updateQuotationRequest } from "../SelectionTile";
import { PreferredInstallLocation } from "./PreferredInstallLocation";
import { CablerunSelector } from "../zappi/CableRun"
import {WaterHeaterSelector} from "./WaterHeaterType";
import { Fileupload } from '../zappi/PhotoUpload';

/*
 *   Sub steps for installation
 *   include:
 *
 *   1. Select property type
 *   2. Select location in dwelling
 *   3. Provide extra details as a comment
 */

export const InstallationDetailsForm = ({ onNextStep, onBack, visitorId }) => {
  const [substep, setSubstep] = useState(1);
  var meterboxInstallation;
  const substeps = [
    <PropertyType
      visitorId={visitorId}
      onBack={onBack}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <Fileupload
      visitorId={visitorId}
      type="mainsMeterImage"
      subtext={
        "Please upload a photograph of mains electricity meter/fuse rating."
      }
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <PreferredInstallLocation
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={(installInMeterbox) => {
        if(installInMeterbox) {
          meterboxInstallation = true;
          setSubstep(substep + 2)
        } else {
          setSubstep(substep + 1)
        }
      }}
    />,
    <CablerunSelector
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <Fileupload
      visitorId={visitorId}
      type="eddiInstallLocation"
      subtext={
        "Please upload a photograph of your preferred install location."
      }
      onBack={() => {
        if (meterboxInstallation) {
          setSubstep(substep - 1)
        } else {
          setSubstep(substep - 2)
      }}}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <WaterHeaterSelector
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={() => setSubstep(substep + 1)}
    />,
    <FinalComments
      visitorId={visitorId}
      onBack={() => setSubstep(substep - 1)}
      onNextStep={onNextStep}
    />,
  ];

  return substeps[substep - 1];
};
